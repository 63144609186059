.hero {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 1.9);
  }
  .hero img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .hero-text h1 {
    font-size: 3rem;
    font-weight: 600;
    background: red;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 22px;
  }
  .hero-text p {
    font-size: 16px;
    font-weight: 500;
    color: rgb(234, 231, 220);
    margin-left: 22px;
  
    padding: 0.5rem 0 2rem 0;
  }
  .hero-text .show {
    text-decoration: none;
    color: white;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6px;
    margin-left: 22px;
    padding-top: -16px;
  }
  
  @media screen and (max-width: 768px) {
    .hero img {
      max-height: 300px;
      background-color: black;
      opacity: 0.5;
    }
  
    .hero-text h1 {
      font-size: 16px;
    }
    .hero-text p {
      font-size: 12px;
      color: #ffffff;
    }
    .hero-text Buttonnning {
      font-size: 12px;
    }
  }
  