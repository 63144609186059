@import url(https://fonts.googleapis.com/css2?family=Inter+Tight&family=Inter:wght@200;400;600&family=Montserrat:wght@500&family=Poppins:wght@200;400;600&family=Press+Start+2P&family=Red+Rose:wght@700&family=Roboto:wght@700&family=Russo+One&family=Titillium+Web:wght@700&display=swap);
@charset "UTF-8";
.fontsforweb_fontid_1080 {
  font-family: "Poppins", sans-serif;
}

.paddding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
  color: #fff !important;
  padding-top: 12px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin: 0 20px !important;
  transition: all 0.5s ease;
  background: #f5bc04 !important;
  -webkit-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  -moz-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  -ms-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
}

.navbar-light .navbar-nav .nav-link {
  color: #222 !important;
  padding-top: 12px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin: 0 20px !important;
  transition: all 0.5s ease;
  position: relative !important;
  -webkit-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  -moz-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  -ms-clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  background: #f5bc04 !important;
  color: #fff !important;
  padding-top: 12px !important;
  padding-bottom: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin: 0 20px;
  position: relative;
}

.bg-faded {
  background-color: #f7f7f7;
}

.mobile_logo_width {
  display: none;
}

.navbar-nav {
  flex-direction: row !important;
  cursor: pointer;
}

.heading {
  font-size: 20px;
  color: #424040;
  font-weight: 600;
}

.input-text-box {
  border-radius: 0 !important;
  font-size: 15px !important;
  height: 45px !important;
  letter-spacing: 1px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #f5bc04 !important;
}

.btn-sign-in {
  background: #c43421 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 10px 50px !important;
  border-radius: 5px !important;
}

.btn-sign-up {
  background: #4287f5 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 10px 50px !important;
  border-radius: 5px !important;
}

.blog-form input:focus {
  border-color: #f5bc04 !important;
}

.description-box {
  border-radius: 0 !important;
  height: 150px;
  resize: none;
  padding-top: 20px !important;
  letter-spacing: 1px !important;
}

.btn-add {
  background: #bf7034 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 10px 50px !important;
}

.react-tag-input {
  height: 45px !important;
}

.react-tag-input:focus {
  border-color: #f5bc04 !important;
}

.catg-dropdown {
  width: 100%;
  border-radius: 4px;
  height: 45px;
  color: #806a78;
}

.catg-dropdown:focus {
  outline: none !important;
  border-color: #f5bc04;
}

.trending {
  float: left;
  color: #806a78;
  font-size: 18px;
  margin: auto;
}

.blog-heading {
  font-size: 24px;
  color: #222;
  border-bottom: 1px solid #777;
}

.blogs-img {
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  top: 0;
  -o-box-shadow: inset 0 2px 3px 1px;
  box-shadow: inset 0 2px 3px 1px;
  transition: all all 0.5s ease;
}

.blogs-img > img {
  height: 260px;
  min-width: 100%;
}

.hover-blogs-img:hover > .blogs-img {
  top: -7px;
  -o-box-shadow: inset 0 2px 3px 1px;
  box-shadow: inset 0 2px 3px 1px;
}

.title {
  font-size: 17px;
  color: #222 !important;
  font-weight: 800;
  font-family: "Poppins";
  text-decoration: none;
  display: block;
}

.category {
  display: inline-block;
  position: relative;
  background-color: #4287f5;
  padding: 4px 8px;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  text-transform: none;
  line-height: 16px;
  font-weight: 700;
  border-radius: 4px;
}

.fa-trash {
  color: #c43421 !important;
}

.fa-edit {
  color: lightsalmon !important;
}

.meta-info {
  font-size: 15px;
  color: #222;
  display: block;
  font-family: "Poppins";
  text-decoration: none;
}

.blog-single-content .meta-info {
  margin-top: 8px;
  border-bottom: 1px solid #777;
  margin-bottom: 15px;
}

.author {
  display: inline-block;
  font-weight: 600;
  font-size: 15px !important;
}

.short-description {
  font-size: 14px;
  color: #777;
}

.btn-read {
  background: #544e66 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 5px 10px !important;
  float: left;
  margin-top: 10px;
  font-size: 14px;
}

.blog-title-box {
  width: 38.2%;
  z-index: 555;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.single .blog-title-box {
  position: relative;
  height: 700px;
  width: 100%;
}
.blog-title-box .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
}
.blog-title-box .blog-title {
  z-index: 12;
  position: absolute;
  bottom: 0;
  padding: 30px;
}
.single .blog-title-box .blog-title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.blog-title-box .blog-title h2 {
  color: white;
  font-weight: 300;
}
.single .blog-title-box .blog-title h2 {
  font-size: 72px;
}
.blog-title-box .blog-title > span {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}

.blog-single-content {
  z-index: 999;
  background: white;
}
.blog-single-content p, .blog-single-content ul li {
  font-size: 1.05rem;
}

.spinner {
  width: 3rem;
  height: 3rem;
}

.tags > a {
  text-decoration: none;
}

.tag {
  background: #f1f1f1;
  padding: 10px 15px;
  color: #222;
  transition: all 0.5s ease;
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.tag:hover, .tag:focus {
  background: #f5bc04;
  color: #fff;
  text-decoration: none;
}

.most-popular-img {
  height: 80px;
  width: 100%;
}

.most-popular-font {
  font-weight: 500;
}

.most-popular-font-meta {
  font-size: 12px;
  color: #777;
}

.trending-img-position {
  position: relative;
  overflow: hidden;
  z-index: 5;
  cursor: pointer;
}

.trending-img-size {
  height: 326px;
  overflow: hidden;
}

.trending-img-size > img {
  height: 340px;
  min-width: 100%;
}

.trending-img-relative {
  position: relative;
}

.trending-img-absolute {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  z-index: 7;
}

.trending-img-absolute-1 {
  position: absolute;
  width: 100%;
  z-index: 9;
  padding: 10px;
  bottom: 0;
}

.trending-meta-info {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}

.trending-img-position:hover {
  -webkit-animation: swing1 1s ease;
          animation: swing1 1s ease;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes swing1 {
  15% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  30% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  65% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes swing1 {
  15% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  30% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  65% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.form-inline {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

.search-btn {
  float: left;
}

.widget {
  position: relative;
  display: block;
  margin-bottom: 3rem;
}

.widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link-widget li {
  padding: 1.2rem 0.8rem 1.7rem;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 500;
  position: relative;
  margin: 0;
  border-bottom: 1px dashed #dadada;
}

.link-widget li:last-child {
  border-bottom: 0 dashed #dadada;
}

.link-widget li span {
  position: absolute;
  right: 0;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  transition: all 0.5s ease;
  cursor: pointer;
  z-index: 1;
}
.scroll-to-top span {
  width: 50px;
  height: 50px;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  border-radius: 4px;
}
.scroll-to-top i {
  height: 20px;
  display: table-cell;
  vertical-align: middle;
}

.related-content {
  border-radius: 20px;
  cursor: pointer;
}

.custombox {
  position: relative;
  padding: 3rem 2rem;
  border: 1px dashed #dadada;
  margin-top: 20px;
}

.comments-list p {
  margin-bottom: 1rem;
}

.comments-list .media-right,
.comments-list small {
  color: #999 !important;
  font-size: 11px;
  letter-spacing: 2px;
  margin-top: 5px;
  padding-left: 10px;
  text-transform: uppercase;
}

.media {
  display: flex;
  align-items: flex-start;
}

.comments-list .media {
  padding: 1rem;
  margin-bottom: 15px;
}

.media-body {
  flex: 1 1;
}

.media-body .btn-primary {
  padding: 5px 15px !important;
  font-size: 11px !important;
}

.comments-list img {
  max-width: 80px;
  margin-right: 30px;
}

.small-title {
  background: #edeff2 none repeat scroll 0 0;
  font-size: 16px;
  font-weight: 700;
  left: 5%;
  line-height: 1;
  margin: 0;
  padding: 0.6rem 1.5rem;
  position: absolute;
  text-align: center;
  top: -18px;
  color: #ffffff !important;
  background-color: #74a044 !important;
  border-color: #74a044 !important;
}

.user_name {
  font-size: 16px;
  font-weight: 600;
}

.btn_mange_pagging {
  background: #dadada;
  padding: 12px 20px;
  color: #222 !important;
  font-weight: 800;
  margin: 0 10px;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.btn_mange_pagging:hover, .btn_mange_pagging:focus {
  background: #222;
  color: #fff !important;
  font-weight: 800;
}

.btn_pagging {
  background: #dadada !important;
  color: #222;
  padding: 12px 20px !important;
  font-weight: 800;
  margin: 0 10px;
  transition: all 0.5s ease;
}

.btn_pagging:hover, .btn_pagging:focus {
  background: #222 !important;
  color: #fff;
}

.scroll {
  width: auto;
  height: 230px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.carousel-row {
  height: 100%;
}
.carousel-row .imageSlider {
  height: 100%;
  z-index: 9;
  overflow: visible;
}

.afterTextBox .slick-prev:before,
.afterTextBox .slick-next:before {
  color: #000 !important;
}
.afterTextBox .slick-next,
.afterTextBox .slick-prev {
  border-radius: 0px;
  width: 60px;
  height: 100%;
  z-index: 1;
}
.afterTextBox .slick-next {
  height: 100%;
  left: auto;
  top: 0;
}
.afterTextBox .slick-prev {
  /* right: 45px; */
  top: 0;
  left: 0;
}
.afterTextBox .slick-prev {
  left: -7px !important;
}
.afterTextBox .slick-prev:before {
  content: "‹";
}
.afterTextBox .slick-next:before {
  content: "›";
}
.afterTextBox .slick-prev:before,
.afterTextBox .slick-next:before {
  font-family: "slick";
  font-size: 45px;
  line-height: 2px;
}
.afterTextBox .slick-dots li {
  margin: 0;
}
.afterTextBox .slick-dots li button:before {
  color: #8D8D8D;
  font-size: 15px;
  opacity: 1;
}
.afterTextBox .slick-dots {
  position: absolute;
  bottom: 30px;
}
.afterTextBox .slick-dots li.slick-active button:before {
  color: #464646;
  opacity: 3;
}

.scaleImageClass1 {
  margin: 0;
  overflow: hidden;
  padding: 0px;
  position: relative;
  height: 100%;
  max-height: 750px;
}

.scaleImageClass1 img {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  object-fit: cover;
}

.imageSlider .leftText {
  position: absolute;
  padding: 10px;
  bottom: 40px;
  left: 0;
  z-index: 999 !important;
  overflow: visible;
}

.displayCenter {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.displayCenterBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-next {
  right: 0px !important;
}

.slick-prev {
  left: 0px !important;
  z-index: 11;
}

input::-webkit-input-placeholder {
  color: #000 !important; /* Replace #999 with your desired placeholder color */
}

input::placeholder {
  color: #000 !important; /* Replace #999 with your desired placeholder color */
}

.displaySpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}/*# sourceMappingURL=style.css.map */
.jodit-workplace+.jodit-status-bar:not(:empty){
  padding: 30px 10px;
}
@media screen and (max-width:615px) {
  
  .jodit-container:not(.jodit_inline) .jodit-workplace{
    height: 120px !important;
    padding: 5px !important;
  }
}

.dropbtn {
  background: transparent;
  /* padding: 16px; */
  padding: 8px;
  border: none;
  line-height: 24px;
  color: #222222;
  cursor: pointer;
  font-size: 17px;
  font-family: system-ui;
  font-weight: 500;
  position: relative;
}
.dropdown-content {
  position: absolute;
  background-color: white;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
}
  .dropbtn:hover .dropdown-content {
    display: block;
  }
.hero {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 1.9);
  }
  .hero img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  .hero-text h1 {
    font-size: 3rem;
    font-weight: 600;
    background: red;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 22px;
  }
  .hero-text p {
    font-size: 16px;
    font-weight: 500;
    color: rgb(234, 231, 220);
    margin-left: 22px;
  
    padding: 0.5rem 0 2rem 0;
  }
  .hero-text .show {
    text-decoration: none;
    color: white;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6px;
    margin-left: 22px;
    padding-top: -16px;
  }
  
  @media screen and (max-width: 768px) {
    .hero img {
      max-height: 300px;
      background-color: black;
      opacity: 0.5;
    }
  
    .hero-text h1 {
      font-size: 16px;
    }
    .hero-text p {
      font-size: 12px;
      color: #ffffff;
    }
    .hero-text Buttonnning {
      font-size: 12px;
    }
  }
  
.c1 {
    text-align: center;
    color: #ff7800;
    margin: 30px 0px 20px 0px;
  }
  .c1 h1 {
    font-size: 30px;
  }
  .c1 h2 {
    font-size: 25px;
  }
  .fullcont {
    background-color: #ffffff;
    text-align: center;
    justify-content: start;
  }
  .cont1 {
    box-shadow: 0px 0px 10px 0px #f1a3a3b1;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 7px;
    margin-right: 7px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border-radius: 5px solid;
  }
  @media screen and (max-width: 768px) {

  .c1 h1 {
    font-size: 22px;
    margin-top: 12px;
  }
  .c1 h2 {
    font-size: 20px;
  }.fullcont {
    box-sizing: border-box;
    display: block;
    border-radius: 50px 50px 50px 50px;
    align-items: center;
  }
  .cont1 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.Popularall {
    /* text-align: center; */
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 14px;
    
  
  }
  .Popularall h1 {
    
    color: #000000;
  
  font-size: 20px;
  
  font-weight: 600;
  }
  
  .Popularall p {
    color: black;
  }
  .about-content {
    text-align: center;
  }
  .about-content p {
    padding-top: 12px;
    font-size: 22px;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
  }
  .buttonabout {
    text-align: center;
  }
  .buttonabout button {
    background-color: #4caf50;
    color: #ffffff;
    padding: 6px 12px 6px 12px;
  }
  .Ceopart {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .ceofounder1 {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .ceofounder1 p {
    font-size: 18px;
  }
  .ceofounder1 h1 {
    color: #ff7800;
    font-weight: 500;
  }
  
  @media screen and (max-width: 768px) {

  .about-content p {
    padding-top: 12px;
    font-size: 14px;

    justify-content: center;
  }
  .ceofounder1 h1 {
    font-size: 14px;
  }
  .ceofounder1 p {
    font-size: 12px;
  }
  .ceofounder img {
    width: 100%;
    object-fit: contain;
  }
  .Popularall h1 {
    left: 0;
  }
}
