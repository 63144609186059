.c1 {
    text-align: center;
    color: #ff7800;
    margin: 30px 0px 20px 0px;
  }
  .c1 h1 {
    font-size: 30px;
  }
  .c1 h2 {
    font-size: 25px;
  }
  .fullcont {
    background-color: #ffffff;
    text-align: center;
    justify-content: start;
  }
  .cont1 {
    box-shadow: 0px 0px 10px 0px #f1a3a3b1;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 7px;
    margin-right: 7px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border-radius: 5px solid;
  }
  @media screen and (max-width: 768px) {

  .c1 h1 {
    font-size: 22px;
    margin-top: 12px;
  }
  .c1 h2 {
    font-size: 20px;
  }.fullcont {
    box-sizing: border-box;
    display: block;
    border-radius: 50px 50px 50px 50px;
    align-items: center;
  }
  .cont1 {
    margin-left: 10px;
    margin-right: 10px;
  }
}