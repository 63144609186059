.Popularall {
    /* text-align: center; */
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 14px;
    
  
  }
  .Popularall h1 {
    
    color: #000000;
  
  font-size: 20px;
  
  font-weight: 600;
  }
  
  .Popularall p {
    color: black;
  }
  .about-content {
    text-align: center;
  }
  .about-content p {
    padding-top: 12px;
    font-size: 22px;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
  }
  .buttonabout {
    text-align: center;
  }
  .buttonabout button {
    background-color: #4caf50;
    color: #ffffff;
    padding: 6px 12px 6px 12px;
  }
  .Ceopart {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .ceofounder1 {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .ceofounder1 p {
    font-size: 18px;
  }
  .ceofounder1 h1 {
    color: #ff7800;
    font-weight: 500;
  }
  
  @media screen and (max-width: 768px) {

  .about-content p {
    padding-top: 12px;
    font-size: 14px;

    justify-content: center;
  }
  .ceofounder1 h1 {
    font-size: 14px;
  }
  .ceofounder1 p {
    font-size: 12px;
  }
  .ceofounder img {
    width: 100%;
    object-fit: contain;
  }
  .Popularall h1 {
    left: 0;
  }
}